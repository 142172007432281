$colour-plain: #fff;
$colour-primary: #006272;
$colour-secondary: #84bd00;
$colour-accent: #f1f1f1;
$colour-accent-secondary: #d0d0ce;
$colour-accent-dark: #2c2a29;
$colour-success: #84bd00;
$colour-success-light: #f3ffd6;
$colour-error: #c55050;
$colour-plain-transparent: transparentize($colour-plain, 0.6);
$colour-primary-dark: darken($colour-primary, 10%);
$colour-primary-light: lighten($colour-primary, 4%);
$colour-accent-transparent: transparentize($colour-accent, 0.4);
$colour-accent-secondary-dark: darken($colour-accent-secondary, 10%);
$colour-accent-dark-transparent-semi: transparentize($colour-accent-dark, 0.2);
$colour-accent-dark-transparent: transparentize($colour-accent-dark, 0.6);
$colour-accent-dark-transparent-almost: transparentize($colour-accent-dark, 0.91);
$colour-accent-dark-transparent-full: transparentize($colour-accent-dark, 1);
$heading-one-transform: none;
$heading-transform: none;
$cta-primary-capitalisation: none;
$cta-tertiary-capitalisation: none;
$border-radius: 5px;
$cta-border-radius: 5px;
$typefaces: 'white-label';

@import 'shared/mixins/font-face';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;600;800&display=swap&subset=arabic');
@include font-face('icons', 'avis-icons', 'avis-icons');
@import 'brand';

.promo__details {
	background: $colour-secondary;
	color: $colour-plain;

	.l-row--variant-a & {
		background: $colour-secondary;
	}

	.l-row--variant-d & {
		background: $colour-accent-dark;
	}
}
